import 'mdn-polyfills/NodeList.prototype.forEach';
import CardSlider from './CardSlider';

document.addEventListener('DOMContentLoaded', () => {
  const cardSliders = document.querySelectorAll('.js-card-slider');
  if (!cardSliders.length) {
    return;
  }

  cardSliders.forEach((element) => {
    const cardSlider = new CardSlider(element);
    cardSlider.mount(element);
  });
});
