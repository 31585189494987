// import { throttle, debounce } from 'throttle-debounce';

import "./front/components/header/lifecycle";
import "./front/components/breadcrumb/lifecycle";
import "./front/components/form/lifecycle";
import "./front/components/share/lifecycle";
import "./front/components/slider/lifecycle";
import "./front/components/cardSlider/lifecycle";
import "./front/components/footer/lifecycle";
// TODO Christna: pq je peux pas supprime map
import "./front/components/map/lifecycle";
import "./front/components/toggler/lifecycle";
// import "./front/components/dataGouv/lifecycle";
import "./front/components/summary/lifecycle";


document.documentElement.style.setProperty('--vh', `${window.innerHeight/100}px`);

window.addEventListener('resize', handleResize);

function handleResize(){
  document.documentElement.style.setProperty('--vh', `${window.innerHeight/100}px`);
}
