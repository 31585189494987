import 'mdn-polyfills/NodeList.prototype.forEach';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

export default class CardSlider {
  constructor(element) {
    this.element = element;
    this.next = document.querySelector(".js-card-slider-next");
    this.prev = document.querySelector(".js-card-slider-prev");

    this.settings = {
      modules: [Navigation],
      navigation: {
        nextEl: this.next,
        prevEl: this.prev,
      },
      spaceBetween: 24,
      slidesPerView: 'auto',
      freeMode: true,
      loop: false,
    };

    this.swiper;
  }


  mount() {
    this.swiper = new Swiper(this.element, this.settings);
    this.element.classList.add('is-mounted');
    }

  unmount() {
    this.element.classList.remove('is-mounted');
    this.destroy(this.swiper, true);
  }
}
